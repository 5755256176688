import './home.css'
import MainHeader from '../../components/MainHeader'
import WhatWeOffer from '../../components/WhatWeOffer' 
import FAQs from '../../components/FAQs'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

const Home = () => {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname]);
  return (
    <>
    <MainHeader/>
    <WhatWeOffer/>
    <FAQs/>
    </>
    
  )
}

export default Home