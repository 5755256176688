

const Header = ({title, image, children, style, style2}) => {
  return (
    <header className="header" style={style}>
        <div className="header__container">
            <div className="header__container-bg" style2={style2}>
                <img src={image} alr="Header Background Image"/>
            </div>
            <div className="header__content">
                <h2>{title}</h2>
                <p>{children}</p>
            </div>
        </div>
    </header>
  )
}

export default Header