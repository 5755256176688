import {Link} from 'react-router-dom'
import Logo from '../images/Tlogo.png'
import { AiFillInstagram } from 'react-icons/ai'
import { FaFacebook} from 'react-icons/fa'


const Footer = () => {
  return (
    <footer>
        <div className="container footer__container">
            <article>
                <Link to="/" className = 'logo'>
                    <img src={Logo} alt = "Footer Logo"/>
                </Link>
                <p>
                    Transforming Spaces, Building Dreams
                </p>
                <div className = "footer__socials">
                    <a href="https://www.instagram.com/renov8squad/" target="_blank" rel='noreferrer noopener'><AiFillInstagram/></a>
                    <a href="https://www.facebook.com/renov8squad/" target="_blank" rel='noreferrer noopener'><FaFacebook/></a>
                </div>
            </article>
            <article>
                <h4>Permalinks</h4>
                <Link to="/about">About</Link>
                <Link to="/gallery">Gallery</Link>
                <Link to="/contact">Contact</Link>
            </article>
            <article>
                <h4>Get In Touch</h4>
                <Link to="/contact">Contact Us</Link>
                
            </article>
        </div>
        <div className="footer__copyright">
            <small>Copyright 2023 RENOV8 INC. All Rights Reserved.</small>
        </div>
    </footer>
  )
}

export default Footer