import {SiOpenaigym} from 'react-icons/si'
import HomeRenovation from './images/HomeRenovation.JPG'
import BasementRenovation from './images/BasementRenovation.JPG'
import KitchenRenovation from './images/KitchenRenovation.JPG'
import Closets from './images/Closets.JPG'
import CommercialRenovation from './images/CommercialRenovation.JPG'


export const links = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "About",
        path: '/about'
    },
    {
        name: "Gallery",
        path: '/gallery'
    },
    {
        name: "Contact",
        path: '/contact'
    }
]







export const offerings = [
    {
        id: 1,
        Image: KitchenRenovation,
        title: "Kitchen Renovation",
        path: "/gallery#KRPATH" 
    },
    {
        id: 2,
        Image: HomeRenovation,
        title: "Home Renovation",
        info: "This is the day that the lord has made. We will rejoice!",
        path: "/gallery#HRPATH"
    },
    {
        id: 3,
        Image: BasementRenovation,
        title: "Basement Renovation",
        info: "This is the day that the lord has made. We will rejoice!",
        path: "/gallery#BRPATH"
    },
    {
        id: 4,
        Image: Closets,
        title: "Bedroom Closets/Bathroom",
        info: "This is the day that the lord has made. We will rejoice!",
        path: "/gallery#BCPATH"
    },
]








export const values = [
    {
        id: 1,
        icon: <SiOpenaigym/>,
        title: "Value One",
        desc: "Placeat quidem facere dicta modi? Pariatur exercitationem illum."
    },
    {
        id: 2,
        icon: <SiOpenaigym/>,
        title: "Value Two",
        desc: "Placeat quidem facere dicta modi? Pariatur exercitationem illum."
    },
    {
        id: 3,
        icon: <SiOpenaigym/>,
        title: "Value Three",
        desc: "Placeat quidem facere dicta modi? Pariatur exercitationem illum."
    },
    {
        id: 4,
        icon: <SiOpenaigym/>,
        title: "Value Four",
        desc: "Placeat quidem facere dicta modi? Pariatur exercitationem illum."
    }
]









export const faqs = [
    {
        id: 1,
        question: "How do I get started with a renovation project?",
        answer: "Getting started with your dream renovation is easy! Simply reach out to us through our website, and we'll schedule a complimentary consultation. During this meeting, our team will visit your space, discuss your ideas, and collaboratively create a design plan that aligns with your vision. Let's turn your dream home into a reality together!",
    },
    {
        id: 2,
        question: "What types of renovations do you specialize in?",
        answer: "RENOV8 specializes in a wide range of renovation services to transform every corner of your home. From comprehensive home renovations encompassing living rooms, home theaters, and offices to complete basement transformations—whether it's turning an unfinished space into a functional area or upgrading existing rooms. We also excel in enhancing bedrooms, including closets and washrooms, and take pride in crafting stunning kitchen renovations, complete with customized cabinets, drawers, and more. Whatever your vision, we have the expertise to bring it to life!"
    },
    {
        id: 3,
        question: "What is the estimated timeline for a renovation project?",
        answer: "Once we've collectively reached a satisfying design and finalized your vision, we move on to the next steps. After contracts are finalized, we anticipate up to 10 business days for the arrival of all necessary materials and components. The actual timeline for completing the renovation depends on the project's size and complexity. Rest assured, we're committed to delivering high-quality results efficiently, and our team will provide you with a more accurate timeline based on the specifics of your project. Your dream space is just around the corner!"
    },
    {
        id: 4,
        question: "Is there a consultation fee?",
        answer: "No, there is no consultation fee! Simply reach out to us, and we'll get back to you within 1-3 business days to set up a free consultation meeting. During this session, we'll discuss your project, share ideas, and provide you with a comprehensive estimate. Your dream renovation journey starts with a hassle-free consultation—contact us today!"
    },
    {
        id: 5,
        question: "How do you ensure the quality of work during the renovation process?",
        answer: "At RENOV8, quality is our priority. Our skilled craftsmen adhere to rigorous standards, using premium materials and proven techniques. We also maintain open communication throughout the project, ensuring your vision is realized with precision and excellence."
    },
    {
        id: 6,
        question: "How do you handle unexpected challenges or changes during the renovation process?",
        answer: "We understand that renovations can sometimes present unexpected challenges. RENOV8 is committed to transparent communication. If changes arise, we'll discuss them with you promptly, providing solutions and adjustments as needed to ensure a seamless and successful project outcome. Your satisfaction is our priority, and we're here to navigate any twists in the renovation journey together."
    }
]








// export const testimonials = [
//     {
//         id: 1,
//         name: "Diana Ayi",
//         quote: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium ipsam facere ea a laboriosam sed? Quod vel voluptates a! Maxime minima cumque aut? In expedita numquam consectetur non officia iusto.",
//         job: "Student",
//         avatar: require("./images/avatar1.jpg")
//     },
//     {
//         id: 2,
//         name: "Daniel Vinyo",
//         quote: "Harum quaerat hic consequuntur molestias repellat ad quo tenetur vitae rem, labore quisquam? Atque, assumenda rerum this and that odit harum quaerat hic praesentium quisquam quae, enim iste ipsam id repellat.",
//         job: "Software Egineer",
//         avatar: require("./images/avatar2.jpg")
//     },
//     {
//         id: 3,
//         name: "Edem Quist",
//         quote: "Quaerat hic praesentium consequuntur molestias repellat ad quo tenetur vitae rem, labore quisquam? Atque, assumenda rerum odit harum quaerat hic praesentium quisquam quae, enim iste ipsam id repellat.",
//         job: "University Lecturer",
//         avatar: require("./images/avatar3.jpg")
//     },
//     {
//         id: 4,
//         name: "Grace Lavoe",
//         quote: "Cupiditate deleniti sint consequuntur molestias repellat ad quo tenetur vitae rem, labore quisquam? Atque, assumenda rerum odit harum quaerat hic praesentium quisquam quae, enim iste ipsam id repellat.",
//         job: "Talking Parrot",
//         avatar: require("./images/avatar4.jpg")
//     },
//     {
//         id: 5,
//         name: "Nana Yaa Dankwa",
//         quote: "Maxime minima cumque sit amet consectetur adipisicing elit. Praesentium ipsam facere ea a laboriosam sed? Quod vel voluptates a! Maxime minima cumque aut? In expedita numquam consectetur non officia iusto.",
//         job: "Pharmacist",
//         avatar: require("./images/avatar5.jpg")
//     }
// ]







export const plans = [
    {
        id: 1,
        name: 'Silver Package',
        desc: 'This package is perfect for beginners who need constant help',
        price: 29.99,
        features: [
            {feature: 'First Feature', available: true},
            {feature: 'Second Feature', available: true},
            {feature: 'Third Feature', available: true},
            {feature: 'Fourth Feature', available: true},
            {feature: 'Fifth Feature', available: true},
            {feature: 'Fifth Feature Plus', available: false},
            {feature: 'Sixth Feature', available: false},
            {feature: 'Seventh Feature', available: false},
            {feature: 'Seventh Feature Plus', available: false},
            {feature: 'Eighth Feature', available: false},
            {feature: 'Ninth Feature', available: false},
            {feature: 'Tenth Feature', available: false},
            {feature: 'Eleventh Feature', available: false}
        ]
    },
    {
        id: 2,
        name: 'Gold Package',
        desc: 'This is the perfect package for beginners who know what their doing',
        price: 49.99,
        features: [
            {feature: 'First Feature', available: true},
            {feature: 'Second Feature', available: true},
            {feature: 'Third Feature', available: true},
            {feature: 'Fourth Feature', available: true},
            {feature: 'Fifth Feature', available: true},
            {feature: 'Fifth Feature Plus', available: true},
            {feature: 'Sixth Feature', available: true},
            {feature: 'Seventh Feature', available: true},
            {feature: 'Seventh Feature Plus', available: true},
            {feature: 'Eighth Feature', available: false},
            {feature: 'Ninth Feature', available: false},
            {feature: 'Tenth Feature', available: false},
            {feature: 'Eleventh Feature', available: false}
        ]
    },
    {
        id: 3,
        name: 'Platinum Package',
        desc: 'This package is perfect for busy people who need home service',
        price: 89.99,
        features: [
            {feature: 'First Feature', available: true},
            {feature: 'Second Feature', available: true},
            {feature: 'Third Feature', available: true},
            {feature: 'Fourth Feature', available: true},
            {feature: 'Fifth Feature', available: true},
            {feature: 'Fifth Feature Plus', available: true},
            {feature: 'Sixth Feature', available: true},
            {feature: 'Seventh Feature', available: true},
            {feature: 'Seventh Feature Plus', available: true},
            {feature: 'Eighth Feature', available: true},
            {feature: 'Ninth Feature', available: true},
            {feature: 'Tenth Feature', available: true},
            {feature: 'Eleventh Feature', available: true}
        ]
    }
]









// const Trainer1 = require('./images/trainer1.jpg')
// const Trainer2 = require('./images/trainer2.jpg')
// const Trainer3 = require('./images/trainer3.jpg')
// const Trainer4 = require('./images/trainer4.jpg')
// const Trainer5 = require('./images/trainer5.jpg')
// const Trainer6 = require('./images/trainer6.jpg')


// export const trainers = [
//     {
//         id: 1,
//         image: Trainer1,
//         name: 'John Doe',
//         job: 'Aerobic Trainer',
//         socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
//     },
//     {
//         id: 2,
//         image: Trainer2,
//         name: 'Daniel vinyo',
//         job: 'Speed Trainer',
//         socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
//     },
//     {
//         id: 3,
//         image: Trainer3,
//         name: 'Edem Quist',
//         job: 'Flexibility Trainer',
//         socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
//     },
//     {
//         id: 4,
//         image: Trainer4,
//         name: 'Shatta Wale',
//         job: 'Body Composition Trainer',
//         socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
//     },
//     {
//         id: 5,
//         image: Trainer5,
//         name: 'Diana Ayi',
//         job: 'Circuit Trainer',
//         socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
//     },
//     {
//         id: 6,
//         image: Trainer6,
//         name: 'Wayne Carter',
//         job: 'Physical Intelligence Trainer',
//         socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
//     }
//]