import {Link} from 'react-router-dom'
import Image from '../images/sample2.JPG'

const MainHeader = () => {
  return (
    <header className="main__header">
      <div className="container main__header-container">
        
        <div className="main__header-left">
          <h1>Transforming Spaces, Building Dreams</h1>
          <p>
            Welcome to RENOV8, where your dream home begins. We specialize in crafting personalized, breathtaking spaces that reflect your unique style and enhance your lifestyle. Let us turn your vision into reality.
          </p>
          <Link to="/about" className = 'btn lg'>Learn More</Link>
        </div>

        <div className="main__header-right">
          <div className="main__header-image">
            <img src={Image} alt="Main Header Image"/>
          </div>
        </div>
      </div>
    </header>
  )
}

export default MainHeader