//import HomeRenovation from '../images/HomeRenovation.JPG'
//import BasementRenovatoin from '../images/BasementRenovation.JPG'
//import KitchenRenovation from '../images/KitchenRenovation.JPG'
//import Closets from '../images/Closets.JPG'
//import CommercialRenovation from '../images/CommercialRenovation.JPG'
import {offerings} from '../data'
import { MdOutlineLocalOffer } from "react-icons/md"
import SectionHead from './SectionHead'
import {Link} from 'react-router-dom'
import {AiFillCaretRight} from 'react-icons/ai'
import Card from '../UI/Card'


const WhatWeOffer = () => {
  return (
    <section className="whatWeOffer">
        <div className = "container whatWeOffer__container">
            <SectionHead icon={<MdOutlineLocalOffer/>} title = "What we Offer"/>
        
            <div className="whatWeOffer__wrapper">
                {
                    offerings.map(({id, Image, title, path}) => {
                        return (
                            
                            <Card className="whatWeOffer__stuff" key={id}>
                                <span><img src={Image} alt=""/></span>
                                <h4>{title}</h4>
                                <Link to={path} className = "btn sm">See More Images<AiFillCaretRight/></Link>
                            </Card>
                        )
                    })
                }
            </div>
        </div>
    </section>
  )
}

export default WhatWeOffer