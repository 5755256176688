import './about.css'
import Header from '../../components/Header'
import HeaderImage from '../../images/internet.jpeg'
import StoryImage from '../../images/Hamid3.JPG'
import VisionImage from '../../images/Vision2.jpg'
import MissionImage from '../../images/Mission.jpeg'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
const About = () => {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname]);
  return (
    <>
    <Header title="About Us" image = {HeaderImage}>
    Step into the world of RENOV8, where craftsmanship meets creativity, and every project is a unique narrative shaped by a decade of experience, a visionary approach, and an unwavering commitment to reinventing living spaces.
    </Header>

    <section className="about__story">
      <div className="container about__story-container">
        <div className="about__section-image">
          <img src={StoryImage} alr="Our Story Image"/>
        </div>
        <div className="about__section-content">
          <h1>Our Story</h1>
          <p>
          As the founder and CEO of RENOV8, I bring over a decade of passionate commitment and hands-on experience to the world of home renovations. My journey in this industry began with a simple vision – to transform houses into homes, creating spaces that not only reflect individual styles but also elevate the quality of life for every homeowner.
          </p>
          <p>
          Having navigated the intricate landscape of home renovations for over ten years, I've had the privilege of witnessing the profound impact that a well-executed renovation can have on both the aesthetics and functionality of a space. This journey has fueled my dedication to assembling a team of skilled professionals who share my passion for craftsmanship and attention to detail.
          </p>
          <p>
          At RENOV8, we believe that every project is a unique opportunity to blend creativity with practicality. Our approach is rooted in collaborative design, where we work closely with our clients to understand their vision, preferences, and lifestyle. From concept to completion, our team is committed to delivering not just renovations but transformative experiences that exceed expectations.
          </p>
          <p>
          Embarking on a renovation journey with RENOV8 means entrusting your vision to a team that values integrity, transparency, and a relentless pursuit of excellence. Join us as we continue to redefine the art of home transformations, one project at a time. Your dream home is not just a possibility; it's a promise we're dedicated to fulfilling.
          </p>
        </div>
      </div>
    </section>
    

    <section className="about__Vision">
      <div className="container about__Vision-container">
        <div className="about__section-content">
          <h1>Our Vision</h1>
          <p>
          At RENOV8, our vision extends far beyond the physical transformation of spaces. We aspire to be pioneers in the realm of home renovations, setting new standards for innovation, sustainability, and client satisfaction. Our vision is rooted in the belief that every home has a unique story waiting to be told through thoughtful design and meticulous craftsmanship.
          </p>
          <p>
          As we look ahead, we envision RENOV8 as not just a renovation company but a trusted partner in the journey of creating homes that resonate with the essence of those who inhabit them. We aim to seamlessly blend aesthetics with functionality, offering tailored solutions that reflect the diverse lifestyles and personalities of our clients. With an unwavering commitment to excellence, our vision is to be at the forefront of shaping living spaces that not only meet but exceed the evolving needs and expectations of our valued clientele. Join us on this visionary quest, where we turn dreams into reality and homes into masterpieces.
          </p>
      
          
        </div>
        <div className="about__section2-image">
          <img src={VisionImage} alr="Our Vision Image"/>
        </div>
      </div>
    </section>



    <section className="about__mission">
      <div className="container about__mission-container">
        <div className="about__section3-image">
          <img src={MissionImage} alr="Our Mission Image"/>
        </div>
        <div className="about__section-content">
          <h1>Our Mission</h1>
          <p>
          At the core of RENOV8's mission is the dedication to enriching lives through the artistry of home renovations. We strive to redefine the renovation experience by prioritizing collaboration, integrity, and unparalleled craftsmanship. Our mission is to empower homeowners with spaces that not only captivate the eye but also serve as functional sanctuaries tailored to their unique preferences and needs.
          </p>
          <p>
          As we embark on each project, our commitment is to deliver excellence from concept to completion. We aim to foster a culture of transparency, ensuring open communication and mutual understanding with our clients. RENOV8's mission is to be a catalyst for positive change in the way people perceive and experience home renovations, setting a new standard for quality, reliability, and innovation. Join us on this mission, where we transform houses into dream homes, one renovation at a time.
          </p>
        </div>
      </div>
    </section>
    </>
  )
}

export default About