import './gallery.css';
import Image from '../../images/sample2.JPG';
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import k1 from '../../images/kitchen/k1.JPG'
import k2 from '../../images/kitchen/k2.JPG'
import k3 from '../../images/kitchen/k3.JPG'
import k4 from '../../images/kitchen/k4.JPG'
import k5 from '../../images/kitchen/k5.JPG'
import k6 from '../../images/kitchen/k6.JPG'
import k7 from '../../images/kitchen/k7.JPG'
import k8 from '../../images/kitchen/k8.jpg'

import h1 from '../../images/home/h1.jpg'
import h2 from '../../images/home/h2.JPG'
import h3 from '../../images/home/h3.jpg'
import h4 from '../../images/home/h4.jpg'
import h5 from '../../images/home/h5.jpg'
import h6 from '../../images/home/h6.jpg'
import h7 from '../../images/home/h7.JPG'

import c1 from '../../images/bedroom/c1.JPG'
import c2 from '../../images/bedroom/c2.JPG'
import c3 from '../../images/bedroom/c3.jpg'
import c4 from '../../images/bedroom/c4.jpg'
import c5 from '../../images/bedroom/c5.jpg'
import c6 from '../../images/bedroom/c6.JPG'
import c7 from '../../images/bedroom/c7.jpg'


import t1 from '../../images/basement/c1.jpg'
import t2 from '../../images/basement/c2.jpg'
import t3 from '../../images/basement/c3.jpg'
import t4 from '../../images/basement/c4.JPG'
import t5 from '../../images/basement/c5.jpg'
import t6 from '../../images/basement/c6.jpg'
import t7 from '../../images/basement/c7.jpg'
import t8 from '../../images/basement/c8.jpg'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import HeaderImage from '../../images/contactHeader.jpeg';
import Header from '../../components/Header';

// import required modules
import  SwiperCore, { Pagination } from 'swiper/modules';

export default function Gallery() {
  const { pathname, hash } = useLocation();
  useEffect(() => {
    if (hash == '') {
      window.scrollTo(0, 0);
    } else {
      let container = document.getElementById(hash.slice(1));
      container.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [pathname]);
  return (
    <>

      <section></section>
      <section className='container KR_container'>
        <h1>Kitchen Renovation</h1>
      </section>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          599: {
            slidesPerView: 1,
          },

          1023: {
            slidesPerView: 2,
            spaceBetween: 30
          }
          
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className='mySwiper'>
        <SwiperSlide>
          <img src={k1} alt="k1"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={k2} alt="k2"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={k3} alt="k3"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={k4} alt="k4"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={k5} alt="k5"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={k6} alt="k6"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={k7} alt="k7"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={k8} alt="k8"/>
        </SwiperSlide>
        <div id='KRPATH'></div>
      </Swiper>



      <section></section>
      <section className='container HomeReno_container'>
        <h1>Home Renovation</h1>
      </section>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },

          1023: {
            slidesPerView: 2,
            spaceBetween: 30
          }
          
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className='mySwiper'>
        <SwiperSlide>
          <img src={h1} alt="h1"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={h2} alt="h2"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={h3} alt="h3"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={h4} alt="h4"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={h5} alt="h5"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={h6} alt="h6"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={h7} alt="h7"/>
        </SwiperSlide>
        <div id='HRPATH'></div>
      </Swiper>

      
      <section></section>
      <section className='container BR_container'>
        <h1>Basement Renovation</h1>
      </section>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },

          1023: {
            slidesPerView: 2,
            spaceBetween: 30
          }
          
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className='mySwiper'>
        <SwiperSlide>
          <img src={t1} alt="t1"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={t2} alt="t2"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={t3} alt="t3"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={t4} alt="t4"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={t5} alt="t5"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={t6} alt="t6"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={t7} alt="t7"/>
        </SwiperSlide>
        
        <div id='BRPATH'></div>
      </Swiper>

      <section></section>
      <section className='container BC_container'>
        <h1>Bedroom Closets/Washrooms</h1>
      </section>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },

          1023: {
            slidesPerView: 2,
            spaceBetween: 30
          }
          
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className='mySwiper'>
        <SwiperSlide>
          <img src={c1} alt="c1"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={c2} alt="c2"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={c3} alt="c3"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={c4} alt="c4"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={c5} alt="c5"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={c6} alt="c6"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={c7} alt="c7"/>
        </SwiperSlide>
        <div id='BCPATH'></div>
      </Swiper>
    </>
  );
}