import './contact.css'
import Header from '../../components/Header'
import HeaderImage from '../../images/contactHeader.jpeg'
import {MdEmail} from 'react-icons/md'
import {BsMessenger} from 'react-icons/bs'

import {AiOutlineMail} from 'react-icons/ai';
import { useRef } from 'react';
import emailjs from 'emailjs-com';
import VisionImage from '../../images/Vision.jpeg'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'


/*<div className="about__section4-image">
          <img src={VisionImage} alr="Our Vision Image"/>
      </div> */
const Contact = () => {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname]);
  
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hmmtjcr', 'template_6f5v7x4', form.current, 'q31vA_As-NY7MGR9H')
      e.target.reset()
  };
  return (
    <>
      <Header title="Get In Touch" image={HeaderImage} className='contact-header'>
      Ready to bring your vision to life? Whether you have a specific project in mind or just want to explore the possibilities, we're here for you. Contact us below, and let's start the conversation about transforming your space into something extraordinary. Your dream home awaits!

      </Header>
      
      <section id='contact'>
      <div className ="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <AiOutlineMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>renov8squad@gmail.com</h5>
            <a href="mailto:renov8squad@gmail.com" target="_blank">Send a Massage</a>
          </article>
          <article className="contact__option">
            <BsMessenger className='contact__option-icon'/>
            <h4>Messenger</h4>
            <a href="http://m.me/renov8squad" target="_blank">Send a Massage</a>
          </article>
        </div>
        {/*END OF CONTACT OPTIONS*/}
        <form ref = {form} onSubmit = {sendEmail}>
          <input type="text" className="text1" name='name' placeholder='Your Full Name' required/>
          <input type="email" className="text1" name='email' placeholder='Your email' required />
          <textarea name="message" className="text1" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
    </>
    
  )
}

export default Contact